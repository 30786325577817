import MaterialIconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import MaterialCommunityIconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import AntDesignFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import FontAwesomeFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';

const MaterialIconFontStyles = `@font-face {
  src: url(${MaterialIconFont});
  font-family: MaterialIcons;
}`;
const MaterialCommunityIconFontStyles = `@font-face {
  src: url(${MaterialCommunityIconFont});
  font-family: MaterialCommunityIcons;
}`;
const AntDesignFontStyles = `@font-face {
  src: url(${AntDesignFont});
  font-family: AntDesign;
}`;

const FontAwesomeFontStyles = `@font-face {
  src: url(${FontAwesomeFont});
  font-family: FontAwesome;
}`;

const icons = [
  MaterialIconFontStyles,
  MaterialCommunityIconFontStyles,
  AntDesignFontStyles,
  FontAwesomeFontStyles,
]

icons.forEach((icon) => {

  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = icon;
  } else {
    style.appendChild(document.createTextNode(icon));
  }

  // Inject stylesheet
  document.head.appendChild(style);
})

